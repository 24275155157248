import React from 'react';


export class Navbar extends React.Component {
    constructor(props) {
        super(props) 
    }

    render() {
        return (
            <nav>
                Site is under construction....
            </nav>
        )
    }
}